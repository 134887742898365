body,
html {
    margin     : 0;
    padding    : 0;
    font-family: Roboto;
    background : #ebeff1;
}

#insert-box,
#list-box,
h1 {
    width       : 86%;
    padding     : 3%;
    margin-left : auto;
    margin-right: auto;
}

.box {
    box-shadow   : 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    background   : white;
}

#insert-box h3,
#insert-box input,
#insert-box button {
    width: 100%;
}

.box h3 {
    margin-top: 0;
}

input {
    font-size     : 16px;
    font-weight   : lighter;
    border        : 0;
    border-bottom : 1px solid #999;
    margin-bottom : 20px;
    padding-bottom: 7px;
}

input:focus {
    outline: none;
}

button {
    background-color: #009688;
    outline         : none;
    border          : none;
    color           : white;
    font-size       : 16px;
    border-radius   : 4px;
    padding         : 1.5%;
    cursor          : pointer;
    box-shadow      : 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

h1 {
    display              : block;
    font-size            : 1.17em;
    -webkit-margin-before: 1em;
    -webkit-margin-after : 1em;
    -webkit-margin-start : 0px;
    -webkit-margin-end   : 0px;
    font-weight          : bold;
    margin               : 0 auto;
    padding              : 3%;
    width                : 92%;
}

h3 {
    font-size: 14px;
    color    : rgba(0, 0, 0, 0.54);
}

ul {
    list-style: none;
    padding   : 0 16px;
    display   : inline-block;
    position  : relative;
}

ul#heroes-list li {
    width        : 100%;
    float        : left;
    margin-top   : 6px;
    margin-bottom: 6px;
}

.color-box {
    width        : 20px;
    height       : 20px;
    float        : left;
    margin-right : 11px;
    border-radius: 2px;
    border-width : 1px;
    border-style : solid;
    border-color : grey;
}