#heroes-list {
  width: 100%;
}

.actions {
  float: right;
}

i {
  cursor: pointer;
}